.show-item {
  cursor: pointer;
  font-weight: 700;
  &:hover {
    color: #ff0000;
  }
}
.table {
  tr:nth-child(2n) {
    background-color: #e5e5e5;
  }
  tr:hover {
    background-color: #c7e9c8 !important;
  }
  .first-row {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    background: #d6d6d6;
  }
  .price{
    font-weight: 700;
  }
  thead{
      background: #b4d0b5;
  }
}

.ui-pagination {
  div {
    cursor: pointer;
    height: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .page-style {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px !important;
    height: 30px !important;
    font-weight: 700 !important;
    border-radius: 10px;
  }
  .current {
    background-color: #4caf50 !important;
    min-width: 30px !important;
    height: 30px !important;
    font-weight: 700 !important;
    border-radius: 10px;
    box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.5);
    div {
      color: #fff;
    }
  }
}
